import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

function calculateTotal(state) {
  state.cart.cartTotal = state.cart.items.reduce((accumulator, object) => {

    return parseFloat(accumulator) + parseFloat(object.amount);
  }, 0);
}

function saveToStorage(state) {
  localStorage.setItem('cartTotal',JSON.stringify(state.cart.cartTotal));
  localStorage.setItem('cart',JSON.stringify(state.cart.items));
}

export const store = new Vuex.Store({
  state: {
    snackbar: {
      content: '',
      status: '',
    },
    cart: {
      items: [],
      cartTotal: 0,
    },
    orders: {
      new: 0
    }
  },
  getters: {
    isSnackbarVisible(state) {
      return state.snackbar.isSnackbarVisible
    },
  },
  mutations: {
    SHOW_SNACKBAR(state, value) {
      state.snackbar.content = value.content
      state.snackbar.status = value.status
    },
    async initialiseStore(state) {
      if(localStorage.getItem('cart')){
        state.cart.items = JSON.parse(localStorage.getItem('cart'))
      }
      if(localStorage.getItem('cartTotal')){
        state.cart.cartTotal = parseFloat(localStorage.getItem('cartTotal')) 
      }
      state.orders.new = 0
      return true;
    },
    ADD_TO_CART(state,payload) {

      // check if item exists already in cart
      if (state.cart.items.find(o => o.id ===  payload.product.id)) {
        state.cart.items.find(o => o.id ===  payload.product.id).qty += payload.product.qty
        state.cart.items.find(o => o.id ===  payload.product.id).amount += payload.product.amount
      }
      else {
        //add item
        state.cart.items.push(payload.product)
      }

      //calculating the total
      calculateTotal(state)

      //saving in web storage
      saveToStorage(state)
    },
    REMOVE_FROM_CART(state, payload) {
      const index = state.cart.items.findIndex(o => o.id === payload.product)

      if (index > -1) {
        state.cart.items.splice(index, 1)
      }
      
      calculateTotal(state)
      saveToStorage(state)
    },
    UPDATE_CART(state,payload) {
      //update quantity 
      state.cart.items.find(o => o.id ===  payload.product.id).qty = payload.product.qty
      state.cart.items.find(o => o.id ===  payload.product.id).amount = payload.product.price * payload.product.qty

      calculateTotal(state)
      saveToStorage(state)
    },
    REMOVE_CART(state) {
      while (state.cart.items.length) { 
        state.cart.items.pop(); 
      }

      calculateTotal(state)
      saveToStorage(state)
    },
    UPDATE_NEW(state, payload) {
      state.orders.new = payload.product
      localStorage.setItem('new', JSON.stringify(state.orders.new));
    }
  },
  actions: {
    showSnackbar({ commit }, value) {
      commit('SHOW_SNACKBAR', value)
    },
    addToCart({ commit }, payload) {
      commit('ADD_TO_CART', payload)
    },
    removeFromCart({ commit }, payload) {
      commit('REMOVE_FROM_CART', payload)
    },
    updateCart({ commit }, payload) {
      commit('UPDATE_CART', payload)
    },
    removeCart({ commit }) {
      commit('REMOVE_CART')
    },
    updateNew({ commit }, payload) {
      commit('UPDATE_NEW', payload)
    }
  }
})
