<template>
  <div id="app">
    <v-app>
      <Snackbar/>
      <template v-if="isAdminPages()">
        <Sidebar/>
      </template>
      <template v-else>
        <Navbar v-if="!isLoginPage()"/>
        <router-view></router-view>
      </template>
    </v-app>
  </div>
</template>

<script>
  import Snackbar from '@/components/Snackbar.vue'
  import Navbar from '@/components/Navbar.vue';
  import Sidebar from '@/components/Sidebar.vue'

  export default {
    name: 'App',
    components: { Snackbar, Navbar, Sidebar },
    watch:{
      $route() {
        this.isAdminPages()
        this.isLoginPage()
      }
    },
    created() {
      this.isAdminPages()
    },
    methods: {
      isAdminPages() {
        return ['urgent', 'dashboard', 'orders', 'schedules'].includes(this.$route.name) 
      },
      isLoginPage() {
        return ['login'].includes(this.$route.name)
      }
    },
    async mounted () {
      await this.$store.commit('initialiseStore')
    }
  }
</script>
