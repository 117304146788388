import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
   theme: {
    themes: {
      light: {
        primary: '#8C9B5A',
        secondary: '#A78FB3',
        accent: '#FFD046',
        accent2: '#CDBE61',
      }
    }
  }
})
