import Vue from 'vue'
import Router from "vue-router";

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/customer/Home.vue'),
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../views/customer/Cart.vue'),
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('../views/customer/Success.vue'),
  },
  {
    path: '/admin',
    redirect: () => {
      const authHeaders = localStorage.getItem('adminAuth')
      if (authHeaders) return { name: 'dashboard' }

      return { name: 'login' }
    },
  },
  {
    path: '/admin/urgent',
    name: 'urgent',
    component: () => import('@/views/admin/Urgent.vue'),
  },
  {
    path: '/admin/dashboard',
    name: 'dashboard',
    component: () => import('@/views/admin/Dashboard.vue'),
  },
  {
    path: '/admin/orders',
    name: 'orders',
    component: () => import('@/views/admin/Orders.vue'),
  },
  {
    path: '/admin/schedules',
    name: 'schedules',
    component: () => import('@/views/admin/Schedules.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/admin/Login.vue'),
  }
];

const router = new Router({
  routes,
  base: '/',
  mode: 'history',
  linkActiveClass: "active",
  scrollBehavior () {
    return { top: 0 }
  }
});

router.beforeEach((to, from, next) => {
  const adminAuth = JSON.parse(localStorage.getItem('adminAuth'))
  if (to.path == '/login') {
    if (adminAuth) {
      return next('/admin/dashboard')
    } else {
      return next()
    }
  }
  if (to.path.includes('admin') && !adminAuth) return next('/login')
  if (to.path == '/admin/dashboard' && adminAuth.role_id != 1) {
    return next('/admin/orders')
  }
  return next()
})

export default router
