<template>
  <v-snackbar
    v-model="isSnackbarVisible"
    text
    class="mt-3"
    :color="status"
    location="top"
    timeout="3000"
  >
    <div class="text-center">
      {{ content }}
    </div>
  </v-snackbar>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'Snackbar',
    data () {
      return {
        isSnackbarVisible: false
      }
    },
    computed: {
      ...mapState({
        content: state => state.snackbar.content,
        status: state => state.snackbar.status,
      })
    },
    created () {
      this.$store.subscribe(mutation => {
        if (mutation.type === 'SHOW_SNACKBAR') this.isSnackbarVisible = true
      })
    }
  }
</script>
