<template>
  <v-app id="side-bar">
    <v-navigation-drawer
      app
      color="primary"
      :mini-variant.sync="mini"
      permanent
    >
      <div class="d-flex justify-center">
        <v-img
          :max-width="$vuetify.breakpoint.smAndDown ? 40 : 100"
          aspect-ratio="1"
          :src="require('@/assets/adminlogo.jpeg')"
          class="mt-10 mb-5 rounded-circle"
        ></v-img>
      </div>

      <v-list>
        <v-list-item
          v-for="item in getSiteItems"
          :key="item.id"
          link
          :to="item.path"
          :active-class="$vuetify.breakpoint.smAndDown ? '' : 'active-item'"
          class="white--text"
        >
          <v-list-item-icon
            v-if="$vuetify.breakpoint.smAndDown"
            class="ml-0"
          >
            <v-badge 
              v-if="item.id===3" 
              overlap
              color="accent" 
              :content="$store.state.orders.new" 
              :value="$store.state.orders.new > 0"
            >
              <v-icon color="white">{{item.icon}}</v-icon>
            </v-badge>
            <v-icon v-else color="white">{{item.icon}}</v-icon>
          </v-list-item-icon>
          <v-badge 
            v-if="item.id===3" 
            inline 
            color="accent" 
            :content="$store.state.orders.new" 
            :value="$store.state.orders.new > 0"
          >
            <h3>{{ item.name }}</h3>
          </v-badge>
          <h3 v-else>{{ item.name }}</h3>
        </v-list-item>

        <v-menu v-if="$vuetify.breakpoint.smAndDown" bottom left>
          <v-list>
            <v-list-item
              v-for="item in getSiteItems"
              :key="item.id"
              link
              :to="item.path"
              :active-class="$vuetify.breakpoint.smAndDown ? '' : 'active-item'"
            >
              <v-list-item-icon
                v-if="$vuetify.breakpoint.smAndDown"
                class="ml-0"
              >
                <v-icon color="white">mdi-pen</v-icon>
              </v-list-item-icon>
              <h3>{{ item.name }}</h3>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list>

      <template v-slot:append>
        <v-list>
          <v-list-item v-if="adminProfile" class="white--text">
            <h3 v-if="$vuetify.breakpoint.smAndDown" class="pl-2">{{ adminProfile.name.charAt(0).toUpperCase() }}</h3>
            <h3 v-else>{{ adminProfile.name }}</h3>
          </v-list-item>

          <v-list-item link @click="openLogoutDialog()">
            <v-list-item-icon
              v-if="$vuetify.breakpoint.smAndDown"
              class="ml-0"
            >
              <v-icon color="white">mdi-import</v-icon>
            </v-list-item-icon>
            <h3 class="red--text">Logout</h3>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <div class="main-view">
      <v-main class="pa-3">
        <v-container fluid>
          <router-view></router-view>
        </v-container>
      </v-main>
    </div>

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title>
          Are you sure you want to logout?
        </v-card-title>
        <v-card-actions class="pb-5 pr-5">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="dialog = false"
          >Cancel</v-btn>
          <v-btn
            depressed
            color="primary"
            @click="logout()"
          >Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
  export default {
    name: 'Sidebar',
    data() {
      return {
        dialog: false,
        siteItems: [
          { id: 1, name: 'Urgent', path: '/admin/urgent', icon: 'mdi-star-outline' },
          { id: 2, name: 'Dashboard', path: '/admin/dashboard', icon: 'mdi-currency-usd' },
          { id: 3, name: 'Orders', path: '/admin/orders', icon: 'mdi-cart-outline' },
          { id: 4, name: 'Schedules', path: '/admin/schedules', icon: 'mdi-calendar-blank-outline' }
        ],
        adminProfile: {}
      }
    },
    computed: {
      mini: {
        get() {
          return this.$vuetify.breakpoint.smAndDown
        },
        set() {}
      },
      getSiteItems() {
        if (this.adminProfile.role_id != 1) return this.siteItems.toSpliced(1, 1)
        return this.siteItems
      }
    },
    created() {
      this.getAdminProfile()
    },
    methods: {
      getAdminProfile() {
        this.adminProfile = JSON.parse(localStorage.getItem('adminAuth'))
      },
      openLogoutDialog() {
        this.dialog = true
      },
      logout() {
        localStorage.removeItem('authHeaders')
        localStorage.removeItem('adminAuth')
        this.dialog = false
        this.$router.push('/login')
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '@/style/style.scss';

  #side-bar {
    .main-view {
      padding-left: 265px;

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        padding-left: 65px;
      }
    }
  }
</style>
